
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter,RouteParams } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "sign-up",
  components: {
    Form
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      
      
    });

    const onActivate = () => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      
        let values = {} as any;
        values.token = router.currentRoute.value.query.token
        values.guid = router.currentRoute.value.query.guid
        
        // Send register request
        store
          .dispatch(Actions.ACTIVATION, values)
          .then(() => {
            
            return Swal.fire({
              text: "Your account has been activated",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            })
          })
          .then((result) => { 
                 alert("klaar")
                 router.push({ name: "sign-in" });
         
          })
          .catch(() => {
              Swal.fire({
              text: "Your account could not be activated, "+store.getters.getErrors[0],//todo geert foutmelding weghalen
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            }).then(()=>{router.push({ name: "sign-in" })})
          });
  
    };

     onActivate();

    return {
      registration,
      submitButton,
    };
  },
});
